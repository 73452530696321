export const query = `query getData($externalId: String!, $compid: String!) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  catalog {
    product(compId: $compid, onlyVisible: true) {
      id
      name
      urlPart
      price
      comparePrice
      formattedPrice
      formattedComparePrice
      hasOptions
      media {
        id
        url
        mediaType
        width
        height
        index
      }
      ribbon
      isInStock
      productType
      digitalProductFileItems {
        fileType
      }
      productType
    }
  }
}`;
